



































import { ref, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';
import HTMLContent from '../components/HTMLContent';

export default {
  name: 'CromoFooter',
  components: {
    HTMLContent,
  },
  setup() {
    const { loadBlocks } = useContent();
    const blocks = ref([]);
    const blockReassurance = ref();
    const blockLogo = ref();
    const blockRedirect = ref();
    const blockSocial = ref();
    const blockAdditional = ref();

    useFetch(async () => {
      blocks.value = await loadBlocks({ identifiers: ['footer-reassurance', 'footer-logo', 'footer-redirect', 'footer-social', 'footer-additional', 'text-espace-part', 'popin-siret-content'] });
      blockReassurance.value = blocks.value[0]?.content;
      blockLogo.value = blocks.value[1]?.content;
      blockRedirect.value = blocks.value[2]?.content;
      blockSocial.value = blocks.value[3]?.content;
      blockAdditional.value = blocks.value[4]?.content;
    });

    return {
      blockReassurance,
      blockLogo,
      blockRedirect,
      blockSocial,
      blockAdditional,
    }
  },
};
